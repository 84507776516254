/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {Meta,LocalStorage,Notify,Dialog,Loading} from 'quasar'



export default { config: {"dark":"auto","brand":{"primary":"black","secondary":"#92d400","accent":"#61ce70"}},plugins: {Meta,LocalStorage,Notify,Dialog,Loading} }

